/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {

    createHorse () {
        return new Swiper('#slider--horse', {
            slidesPerView: 3,
            spaceBetween: 20,
            // loop: true,
            navigation: {
                nextEl: '.slider--horse-next',
                prevEl: '.slider--horse-prev',
            },
            pagination: {
                el: '.swiper-pagination--horse',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }

    createNews () {
        return new Swiper('#slider--news', {
            slidesPerView: 2,
            spaceBetween: 40,
            // loop: true,
            navigation: {
                nextEl: '.slider--news-next',
                prevEl: '.slider--news-prev',
            },
            pagination: {
                el: '.swiper-pagination--news',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                }
            }
        })
    }

    createGallery () {
        return new Swiper('#slider--gallery', {
            slidesPerView: 6,
            spaceBetween: 20,
            // loop: true,
            navigation: {
                nextEl: '.slider--gallery-next',
                prevEl: '.slider--gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                },
                1440: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                }
            }
        })
    }

    createProduct (classImg, classThumb, classThumbPrev, classThumbNext) {
        let thumbs = new Swiper(classThumb, {
            slidesPerView: 5,
            spaceBetween: 10,
            // loop: true,
            navigation: {
                nextEl: classThumbNext,
                prevEl: classThumbPrev,
            },
            pagination: {
                el: '.swiper-pagination--product-thumbs',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                },
                320: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                },
                360: {
                    slidesPerView: 3,
                    spaceBetween: 5,
                },
                375: {
                    slidesPerView: 4,
                    spaceBetween: 5,
                },
                414: {
                    slidesPerView: 4,
                    spaceBetween: 5,
                },
                480: {
                    slidesPerView: 4,
                    spaceBetween: 5,
                },
                514: {
                    slidesPerView: 4,
                    spaceBetween: 5,
                },
                640: {
                    slidesPerView: 5,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 5,
                    spaceBetween: 10,
                }
            }
        })

        return new Swiper(classImg, {
            slidesPerView: 1,
            spaceBetween: 10,
            navigation: {
                nextEl: '.slider--product-next',
                prevEl: '.slider--product-prev',
            },
            pagination: {
                el: '.swiper-pagination--product',
                clickable: true
            },
            thumbs: {
                swiper: thumbs,
            },
            breakpoints: {}
        })
    }
    constructor () {
        if (parseInt($('#slider--horse').length) > 0) {
            this.createHorse()
        }

        if (parseInt($('#slider--news').length) > 0) {
            this.createNews()
        }

        if (parseInt($('#slider--gallery').length) > 0) {
            this.createGallery()
        }

        if (parseInt($('.slider-product-images').length) > 0) {
            $(`.slider-product-images`).each((index, item) => {
                let key = $(item).data('key')

                this.createProduct(".slider-product-images--" + key, ".slider-product-thumbs--" + key, ".slider--product-thumbs-prev--" + key, ".slider--product-thumbs-next--" + key)
            })
        }
    }
}
