/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Map from '@/assets/js/Map/Map'
import ButtonOrder from '@/assets/js/Button/Order/ButtonOrder'
import ButtonToggle from '@/assets/js/Button/Toggle/ButtonToggle'
import ButtonMenu from '@/assets/js/Button/Menu/ButtonMenu'
import AppForm from '@/components/Form/AppForm.vue'
import AppFormPhone from '@/components/Form/AppFormPhone.vue'
import AppFormOrder from '@/components/Form/AppFormOrder.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    new SwiperSlider()
    new Map()
    new ButtonOrder()
    new ButtonToggle()
    new ButtonMenu()

    if(document.getElementById('block--form') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    if(document.getElementById('block--form-phone') != null) {
        createApp({})
            .component('block-form-phone', AppFormPhone)
            .use(Maska)
            .use(form)
            .mount('#block--form-phone')
    }

    if(document.getElementById('block--form-order') != null) {
        createApp({})
            .component('block-form-order', AppFormOrder)
            .use(Maska)
            .use(form)
            .mount('#block--form-order')
    }
})
